<template>
    <div class="spinner">
        <div class="spinner__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script>

  export default {
    name: 'LoadingSpinner'
  }

</script>

<style lang="scss">

    $spinner-color: #03b193;

    .spinner {
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;

        &__inner {
            display: block;
            position: relative;
            width: 6rem;
            height: 6rem;

            div {
                animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                border: 0.7rem solid $spinner-color;
                border-color: $spinner-color transparent transparent transparent;
                border-radius: 50%;
                box-sizing: border-box;
                display: block;
                margin: 0.7rem;
                position: absolute;
                width: 6rem;
                height: 6rem;

                &:nth-child(1) {
                    animation-delay: -0.45s;
                }

                &:nth-child(2) {
                    animation-delay: -0.3s;
                }

                &:nth-child(3) {
                    animation-delay: -0.15s;
                }
            } 
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

</style>