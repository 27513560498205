<template>
  <main :class="{ 'has-response': valueProposition, 'has-error': error }">
    <div class="input">
        <h1>What's my <em>Value Proposition?</em></h1>
        <p class="input__instructions">
          Enter the address of any business' website to see how OpenAI interprets its Value Proposition to be.
        </p>
        <div class="input__group">
          <input v-model="url" placeholder="https://" autofocus>
          <button @click="submit">Submit</button>
        </div>
        <div v-if="error" class="input__error">
          {{ error }}
        </div>
    </div>
    <div v-if="valueProposition" class="response">
      <p v-for="(paragraph, index) in paragraphs" :key="index">
        {{ paragraph }}
      </p>
    </div>
    <footer class="footer">
      <h2>A value proposition is essential for a business</h2>
      <p>It communicates the unique benefit customers will receive from the product or service.</p>
      <p>Differentiates the business from competitors.</p>
      <p>Helps attract and retain customers.</p>
      <p class="footer__copyright">&copy;2023 Marketing CoPilot Inc., &copy;2023 Map2 Innovations Inc. All Rights Reserved.</p>
    </footer>
  </main>
  <loading-animation v-if="loading"></loading-animation>
</template>

<script>

  import LoadingAnimation from './LoadingAnimation.vue';

  export default {
    name: 'App',

    components: {
      LoadingAnimation
    },

    data() {
      return {
        url: '',
        valueProposition: '',
        loading: false,
        error: ''
      };
    },

    computed: {
      paragraphs() {

        // Break into pargraphs
        let paragraphs = this.valueProposition.split('\n');
        paragraphs = paragraphs.filter(Boolean);
        return paragraphs;
      }
    },

    methods: {
      submit() {

        // Show spinner
        this.loading = true;

        // Submit URL to API
        window.axios.post(process.env.VUE_APP_API_ENDPOINT, { 
          url: this.url
        }).then((response) => {
          console.debug(response);
          this.error = '';
          if (!response.data.choices || response.data.choices.length === 0) {
            throw new Error('Invalid response'); 
          }
          this.valueProposition = response.data.choices[0].message.content;          
        }).catch((error) => {
          console.error(error);
          this.error = error.message;
        }).finally(() => {
          this.loading = false;
        });
      }
    }
  }

</script>

<style>
</style>
